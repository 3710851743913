import { Layout, ProductsListing } from '@components/common'
import { Container } from '@components/ui'
import { getProductsInCollection } from '@lib/shopify'
import { ShopifyProduct } from '@types'
import { GetStaticPropsContext, InferGetStaticPropsType } from 'next'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Head from 'next/head'

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  const products = await getProductsInCollection('frontpage', locale || 'en-US', 4)

  return {
    props: {
      ...(await serverSideTranslations(locale || 'en-US', ['common', '404', 'cart'])),
      products: products.map((p: { node: ShopifyProduct }) => p.node),
    }
  }
}

export default function NotFound({ products }: InferGetStaticPropsType<typeof getStaticProps>) {
  const { t } = useTranslation(['common', '404'])

  return (
    <>
      <Head>
        <title>{t('404:title')} &mdash; Ennsens</title>
        <meta name="description" content={t('404:description')} />
      </Head>
      <Container>
        <div className="my-20 md:my-32 lg:my-52 text-center">
          <h1 className="text-4xl sm:text-6xl md:text-7xl lg:text-8xl xl:text-9xl">{t('404:title')}</h1>
          <p className="mt-4">
            {t('404:description')}
          </p>
        </div>
        <ProductsListing className="my-20 md:my-32 lg:my-52" title={t('common:labels.favoriteProducts')} products={products} showAll wide />
      </Container>
    </>
  )
}

NotFound.Layout = Layout
